/**
 * @class ProductSearchOption
 * @description This class based object handles transforming the data received from API,
 * After receiving the data, it will append its value into listBillItem
 *
 * @property {String | Number} productId - The ID of the product.
 * @property {String} productName - The name of the product.
 * @property {String} productCode - The code of the product.
 * @property {Number} productCategory - The category of the product.
 * @property {String} productNameVat - The VAT name of the product.
 * @property {String} barCode - The barcode of the product.
 * @property {Number} quantityInStock - The quantity of the product in stock.
 * @property {Number} sellingPrice - The selling price of the product.
 * @property {String} returnSellingPrice - The return selling price of the product.
 * @property {Number} productType - The type of the product.
 * @property {Object[]} listProductInCombo - A list of products included in a combo.
 * @property {Object[]} listAccessoryBonus - A list of accessory bonuses.
 * @property {Object[]} listAccessoryPromotion - A list of accessory promotions.
 * @property {Number} listedPrice - The listed price of the product.
 * @property {Number} merchantId - The ID of the merchant.
 * @property {String} warrantyAddress - The warranty address of the product.
 * @property {String} warrantyPhone - The warranty phone number of the product.
 * @property {Number} warrantyMonthNo - The warranty month number of the product.
 * @property {String} warrantyDescription - The warranty description of the product.
 * @property {Boolean} isSoftwareProduct - The warranty description of the product.
 */
export class ProductSearchOption {
  /**
   * @param {Object} data
   * @param {String | Number} data.productId
   * @param {String} data.productName
   * @param {String} data.productCode
   * @param {Number} data.productCategory
   * @param {String} data.productNameVat
   * @param {String} data.barCode
   * @param {String} data.imeiCode
   * @param {Number} data.quantityInStock
   * @param {Number} data.sellingPrice
   * @param {Number} data.returnSellingPrice
   * @param {Number} data.productType
   * @param {Object[]} data.listProductInCombo
   * @param {Object[]} data.listAccessoryBonus
   * @param {Object[]} data.listAccessoryPromotion
   * @param {Number} data.listedPrice
   * @param {Number} data.merchantId
   * @param {String} data.warrantyAddress
   * @param {Number} data.warrantyPhone
   * @param {Number} data.warrantyMonthNo
   * @param {String} data.warrantyDescription
   * @param {Boolean} data.isSoftwareProduct
   */
  constructor(data) {
    this.productId = data.productId;
    this.productName = data.productName;
    this.productCode = data.productCode;
    this.productCategory = data.productCategory;
    this.productNameVat = data.productNameVat;
    this.barCode = data.barCode;
    this.imeiCode = data.imeiCode;
    this.quantityInStock = data.quantityInStock ? data.quantityInStock : 0;
    this.sellingPrice = data.sellingPrice;
    this.returnSellingPrice = data.returnSellingPrice;
    this.productType = data.productType;
    this.listProductInCombo = data.listProductInCombo;
    this.listAccessoryBonus = data.listAccessoryBonus;
    this.listAccessoryPromotion = data.listAccessoryPromotion;
    this.listedPrice = data.listedPrice || 0;
    this.merchantId = data.merchantId || 1;
    this.warrantyAddress = data.warrantyAddress || '';
    this.warrantyPhone = data.warrantyPhone || '';
    this.warrantyMonthNo = data.warrantyMonthNo || 0;
    this.warrantyDescription = data.warrantyDescription || '';
    this.belongToWarrantyImei = data.belongToWarrantyImei || false;
    this.isSoftwareProduct = data.isSoftwareProduct || false;
    this.isVieonProduct = data.isVieonProduct || false;
  }
}
