<template>
  <div>
    <b-modal
      ref="qr-code-kredivo-modal"
      :titleHtml="modalTitle"
      @hide="hideModal"
      hide-footer
      size="lg"
      no-close-on-backdrop
    >
      <b-row class="qr-content">
        <b-col class="qr-content__left" sm="5">
          <div class="qr-content__code">
            <b-img
              rounded
              v-bind:src="qrCode.image"
              v-bind="imgProps"
              alt="QRCode Img"
            />
            <div class="qr-content__amount">
              <b>Tổng tiền: </b>
              <b>{{ paymentAmount }}</b>
            </div>
          </div>
        </b-col>
        <b-col class="qr-content__right" sm="7">
          <div class="qr-content__desc mb-5">
            <h4 class="mb-6">{{modalTitle}}</h4>
            <div class="step-description">
              <span class="step-number">1</span> Mở Google Play và App Store và
              quét mã QR <v-icon class="qr-code-icon">mdi-qrcode-scan</v-icon>.
            </div>
            <div class="step-description">
              <b>Lưu ý</b>: QR Code để tải app Kredivo.
            </div>
            <div class="step-description">
              <span class="step-number">2</span> Thanh toán Kredivo với Số Tiền
              <span class="qr-code-verify">{{ paymentAmount }}</span
              >.
            </div>
            <div class="step-description">
              <span class="step-number">3</span> Hạn thanh toán:
              <span class="qr-code-verify">19/06/2024 14:19:14</span>.
            </div>
          </div>
          <div>
            <div
              class="qr-content__time d-flex justify-content-center align-item-end mb-3"
            >
              <p class="mb-0">
                Giao dịch đang được khách hàng thực hiện trên App Kredivo!
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { convertPrice } from '@/utils/common';

export default {
  computed: {
    paymentAmount() {
      return this.convertPrice(this.payment.paymentAmount, 'vn-VN', {
        style: 'currency',
        currency: 'VND',
      });
    },
  },
  data() {
    return {
      modalTitle: "Quét mã QR để tải app Kredivo",
      convertPrice,
      imgProps: {
        center: true,
        fluidGrow: true,
        blankColor: '#bbb',
        width: 230,
        height: 230,
      },
      countdown: 300,
      stopCountdown: 0,
      image: null,
      qrCode: {
        code: '',
        image: '',
      },
      payment: {},
    };
  },
  methods: {
    showModal: async function (payment, image) {
      this.payment = payment;
      this.qrCode.image = image;
      this.$refs['qr-code-kredivo-modal'].show();
    },
    hideModal: async function () {
      window.clearInterval(this.timeTrigger);
      this.$refs['qr-code-kredivo-modal'].hide();
    },
    submit() {
      this.hideModal();
    },
  },
};
</script>

<style scoped>
.qr-content {
  padding: 20px 24px;
}
.qr-content__left {
  background: rgb(245, 245, 250);
  border-radius: 16px;
  padding: 16px;
  margin-right: 40px;
  max-width: 38.67%;
}
.qr-content__code {
  background: rgb(255, 255, 255);
  box-shadow: rgb(40 40 43 / 16%) 0px 2px 8px;
  border-radius: 8px;
  padding: 5px;
  margin: auto;
}
.qr-content__amount {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
}
.qr-content__right {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
}

.step-description {
  color: rgb(128, 128, 137);
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.step-number {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(26, 148, 255);
  color: rgb(255, 255, 255);
  margin-right: 8px;
  font-weight: 500;
  flex-shrink: 0;
  text-align: center;
}
.qr-content__time {
  background: rgb(255, 245, 236);
  border-radius: 4px;
  padding: 13px 0px;
  display: block;
  text-align: center;
  margin-top: auto;
}

.qr-code-icon {
  margin: 0 4px;
  font-size: 18px;
  color: #2a2727;
}
.qr-code-verify {
  font-weight: 700;
  color: #ffa800;
  margin: 0 4px;
  display: contents;
}
</style>
