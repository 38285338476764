<template>
  <div>
    <b-modal ref="bill-gift-modal" hide-footer :titleHtml="modalTitle">
      <v-form ref="form">
        <b-form-group label="Chọn sản phẩm quà tặng:">
          <vue-autosuggest
            v-model="searchText"
            :suggestions="filteredOptions"
            @selected="onSelected"
            :limit="10"
            @input="onInputChange"
            :input-props="{
              class: 'autosuggest__input',
              placeholder: 'Chọn sản phẩm quà tặng',
            }"
            :should-render-suggestions="
              (size, loading) => size >= 0 && !loading && searchText !== ''
            "
          >
            <div
              slot-scope="{ suggestion }"
              style="display: flex; align-items: center"
            >
              <div>
                <span class="text-primary"
                  >({{ convertPrice(suggestion.item.sellingPrice) }}) (</span
                >
                <span
                  v-if="suggestion.item.quantityInStock > 0"
                  class="text-success"
                  >Tồn CTB: {{ suggestion.item.quantityInStock }}</span
                >
                <span v-else class="text-danger"
                  >Tồn CTB: {{ suggestion.item.quantityInStock }}</span
                >
                )
                {{ suggestion.item.productName }}
              </div>
            </div>
          </vue-autosuggest>
        </b-form-group>
        <b-table :items="listItem" :fields="fields" bordered hover>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <v-icon
                small
                class="text-danger"
                @click="deleteItem(row.item.id)"
                v-b-tooltip
                title="Xóa"
                >mdi-delete</v-icon
              >
            </div>
          </template>
        </b-table>
        <b-button
          style="fontweight: 600; width: 100px"
          variant="primary"
          size="sm"
          @click="addGift"
          >Áp dụng</b-button
        >
        <b-button
          style="margin-left: 10px; font-weight: 600; width: 70px"
          variant="secondary"
          size="sm"
          @click="hideModal"
          >Hủy</b-button
        >
      </v-form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import decounce from 'debounce';
import { convertPrice, makeToastFaile } from '../../../utils/common';
import { TIME_TRIGGER } from '../../../utils/constants';
import { BILL_ITEM_TYPE } from '../../../utils/enum';
import { v4 } from 'uuid';

export default {
  props: ['billItemId', 'productName', 'storeId'],
  data() {
    return {
      modalTitle: '',
      searchText: '',
      listItem: [],
      fields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'quantityInStock',
          label: 'Tồn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
          tdClass: 'text-right',
        },
        {
          key: 'price',
          label: 'Giá',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'returnPrice',
          label: 'Giá thu lại',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        { key: 'actions', label: '' },
      ],
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
    };
  },
  methods: {
    convertPrice,
    onSelected(option) {
      const productBonus = option.item;
      this.searchText = '';
      if (productBonus.quantityInStock > 0 || productBonus.productType === 4) {
        const accessoryBonus = {
          id: v4(),
          productId: productBonus.productId,
          name: productBonus.productName,
          productNameVat: productBonus.productNameVat,
          code: 'Quà tặng kèm',
          price: productBonus.sellingPrice,
          quantity: 1,
          type: productBonus.productType,
          imeiCode: '',
          totalPrice: productBonus.sellingPrice,
          discount: '',
          quantityInStock: productBonus.quantityInStock,
          storeId: this.storeId,
          billItemType: BILL_ITEM_TYPE.PRODUCT_BONUS,
          checked: true,
          returnPrice: productBonus.returnSellingPrice,
          isGetVat: false,
          comboItems: productBonus.listProductInCombo,
        };
        this.listItem.push(accessoryBonus);
        this.listItem = this.listItem.map((item, index) => {
          return {
            count: index + 1,
            ...item,
          };
        });
      } else {
        makeToastFaile('Số lượng sản phẩm tồn trong kho không đủ!');
      }
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }

      this.searchText = text.trim();
      this.debounceInput();
    },
    debounceInput: decounce(function () {
      const textSearch = this.searchText ? this.searchText.trim() : '';
      this.fetchProduct(textSearch);
    }, TIME_TRIGGER),
    showModal: function () {
      this.searchText = '';
      this.listItem = [];
      this.modalTitle = `<div>
              <span class='d-block' style='font-size: 1.1rem'>Thêm quà tặng cho sản phẩm: <a class='text-primary'>#${this.productName}</a></span>
              </div>`;
      this.$refs['bill-gift-modal'].show();
    },
    hideModal: function () {
      this.$refs['bill-gift-modal'].hide();
    },
    fetchProduct(textSearch) {
      this.options[0].data = [];
      ApiService.get(
        `productSearch?storeId=${this.storeId}&searchProduct=${textSearch}`
      ).then(({ data }) => {
        const products = data.data;
        this.options[0].data = products.map((element) => {
          return {
            productId: element.productId,
            productName: element.productName,
            productCode: element.productCode,
            productNameVat: element.productNameVat,
            barCode: element.barCode,
            quantityInStock: element.quantityInStock,
            sellingPrice: element.sellingPrice,
            returnSellingPrice: element.returnSellingPrice,
            productType: element.productType,
            listProductInCombo: element.listProductInCombo,
            listAccessoryBonus: element.listAccessoryBonus,
            listAccessoryPromotion: element.listAccessoryPromotion,
            listedPrice: element.listedPrice || 0,
            belongToWarrantyImei: element.belongToWarrantyImei,
          };
        });
        this.filteredOptions = [
          {
            data: this.options[0].data,
          },
        ];
      });
    },
    deleteItem(id) {
      const items = this.listItem.filter((item) => item.id !== id);
      this.listItem = items.map((item, index) => {
        return {
          ...item,
          count: index + 1,
        };
      });
    },
    addGift() {
      const listResult = this.listItem.map((item) => {
        return {
          ...item,
          belongBillDetailId: this.billItemId,
        };
      });
      this.$emit('addGift', listResult);
      this.hideModal();
    },
  },
};
</script>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
.autosuggest__results {
  position: absolute;
  width: 100%;
  max-height: 200px;
  z-index: 999;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.35em + 1.1rem + 2px);
  line-height: 1.35;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  font-size: 0.925rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.28rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
</style>
