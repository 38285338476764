export const SEND_BILL_METHOD_OPTIONS = [
  {
    text: 'Không gửi thông tin',
    value: null,
  },
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'SMS',
    value: 'sms',
  },
];
