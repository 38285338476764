<template>
  <div>
    <b-modal
      ref="list-imei-modal"
      hide-footer
      :titleHtml="modalTitle"
      size="lg"
    >
      <v-form ref="form" lazy-validation>
        <b-table
          :items="listItem"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="3"></vcl-table>
          </template>
          <template v-slot:cell(imeiCode)="row">
            <div class="d-flex justify-content-center">
              <span v-text="row.item.imeiCode"></span>
            </div>
          </template>
          <template v-slot:cell(reasonFifo)="row">
            <div class="d-flex justify-content-center" v-if="row.index !== 0">
              <!-- <span v-text="row.item.imeiCode"></span> -->
              <b-form-select
                size="sm"
                required
                class="mb-3"
                v-model="row['item']['selectImeiReason']"
                :options="listReson"
                value-field="id"
                text-field="name"
                disabled-field="notEnabled"
              >
              </b-form-select>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <v-icon
                small
                class="text-danger"
                @click="applyImei(row.item.imeiCode, row.index)"
                v-b-tooltip
                title="Áp dụng"
                >mdi-pen</v-icon
              >
            </div>
          </template>
        </b-table>
        <b-button
          style="font-weight: 600; width: 70px"
          variant="secondary"
          size="sm"
          @click="hideModal"
          >Hủy</b-button
        >
      </v-form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { VclTable } from 'vue-content-loading';
import { convertPrice, makeToastFaile } from '../../../utils/common';
export default {
  props: ['billItemId', 'productName', 'storeId'],
  data() {
    return {
      modalTitle: '',
      searchText: '',
      listItem: [],
      fields: [
        {
          key: 'imeiCode',
          label: 'IMEI',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '40%',
          },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '30%',
          },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'reasonFifo',
          label: 'Chọn lí do sai FIFO',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '60%',
          },
        },
        { key: 'actions', label: '' },
      ],
      listReson: [],
      onLoading: false,
    };
  },
  components: {
    VclTable,
  },
  watch: {},
  methods: {
    convertPrice,
    fetchData(productId) {
      this.onLoading = true;
      const param = {
        productId: productId,
        storeId: this.storeId,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('imeis/get-by-product', paramQuery)
        .then(({ data }) => {
          this.listItem = data.data.map((element) => {
            return {
              id: element.id,
              imeiCode: element.imeiNo,
              originalPrice: element.originalPrice ? element.originalPrice : 0,
              sellingPrice: element.sellingPrice ? element.sellingPrice : 0,
              selectImeiReason: null,
            };
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    fetchReason() {
      this.onLoading = true;

      // product/select-imei-reason
      ApiService.get('product/select-imei-reason')
        .then(({ data }) => {
          if (data.data) {
            this.listReson = data.data.map((item) => {
              return {
                id: item,
                name: item,
              };
            });
          }
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },

    showModal: function(productId) {
      this.fetchData(productId);
      this.fetchReason();
      this.modalTitle = `<div>
              <span class='d-block' style='font-size: 1.1rem'>Danh sách IMEI sản phẩm: <a class='text-primary'>#${this.productName}</a></span>
              </div>`;
      this.$refs['list-imei-modal'].show();
    },
    hideModal: function() {
      this.$refs['list-imei-modal'].hide();
    },
    applyImei(imeiCode, index) {
      if (index !== 0 && !this.listItem[index]['selectImeiReason']) {
        return makeToastFaile('Vui lòng chọn lí do sai fifo');
      }
      this.$emit(
        'applyImei',
        imeiCode,
        this.listItem[index]['selectImeiReason'],
      );
      this.hideModal();
    },
  },
};
</script>
